<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<module-header
						name="Watchtower settings"
						:workspace-selector="true"
						:add-label="addNewLabel"
						@add-new="handleAddNew"
				>
				</module-header>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<div class="row-format" style="flex-wrap: wrap">
					<div
							:class="`page-link ${tab === page.value ? 'page-link-active' : ''}`"
							v-for="page in pages"
							:key="page.value"
							@click.stop="setView(page.value,$event)"
					>
						<div>{{ page.label }}</div>
					</div>
				</div>
				<ftc-san-list v-if="tab === 'DNC'" ref="DNC"></ftc-san-list>
				<rnd-connection-list v-else-if="tab === 'RND'" ref="RND"></rnd-connection-list>
				<business-record-list v-else-if="tab === 'KYC'" ref="KYC"></business-record-list>
				<v-btn class="primary-action mt-8" v-else>This feature is not active on your account.  Click here to request activation.</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import {defineComponent} from 'vue';
import ModuleHeader from "@/components/ModuleHeader";
import FtcSanList from "@/modules/watchTower/FtcSanList";
import BusinessRecordList from "@/modules/watchTower/BusinessRecordList";
import RndConnectionList from "@/modules/watchTower/RndConnectionList";

export default defineComponent({
	name: 'WatchTowerConfig',

	props: [],

	components: {RndConnectionList, BusinessRecordList, FtcSanList, ModuleHeader},

	data: function () {
		return {
			tab: "KYC",
			pages: [
				{ label: 'KYC', value: 'KYC' },
				{ label: 'National Do Not Call', value: 'DNC' },
				{ label: 'Reassigned Number Database', value: 'RND' },
				{ label: 'Verisk', value: 'Verisk' },
				{ label: 'Active Prospect', value: 'ActiveProspect' }
			]
		};
	},

	mounted() {
	},

	beforeUnmount() {
	},

	methods: {
		setView: function(view) {
			this.tab = view;
		},
		handleAddNew: function(){
			this.$refs[this.tab].addNew();
		}
	},

	computed: {
		addNewLabel: function(){
			if((this.tab === 'DNC' || this.tab === 'KYC') && !this.isSuperAdmin){
				return null;
			}else{
				return 'Add new';
			}
		},

		isSuperAdmin: function () {
			return this.$store.state.user.userType === 'SuperAdmin';
		},
	},
});
</script>

<style scoped lang="scss"></style>
