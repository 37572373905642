<template>
	<div id="workspace-edit" :class="`panel-modal ${$vuetify.display.mobile ? '' : 'desktop'}`" v-if="workspace">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<p-icon class="mr-2 pointer" @click.stop="$emit('result', workspace)">close</p-icon>
				<div class="brand-medium font-18">{{ workspace.id ? 'Edit' : 'Create' }} workspace</div>
				<div class="ml-auto row-format align-center">
					<v-btn elevation="0" color="secondary" @click.stop="save()" :disabled="!isValid"> Save </v-btn>
					<p-icon :size="20" color="gray_70" class="pointer ml-2" @click="confirmDelete()">delete</p-icon>
				</div>
			</div>
		</div>
		<div class="panel-modal-body" v-if="workspace">
			<v-form ref="form" v-model="isValid" @submit.prevent>
				<div class="column-format gap-3 pt-3">
					<v-text-field
						label="Workspace name"
						v-model="workspace.name"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
					></v-text-field>
					<v-select
						v-if="isSuperAdmin"
						v-model="workspace.allowFullCoverageCampaigns"
						label="Enable full coverage campaigns"
						:items="enableOptions"
						item-title="label"
						item-value="value"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
					>
					</v-select>
					<v-select
						v-if="isSuperAdmin"
						v-model="workspace.maxNumbersPerFullCoverageCampaign"
						label="Max numbers per location"
						:items="countOptions"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						:disabled="!workspace.allowFullCoverageCampaigns"
					>
					</v-select>
					<v-select
							v-if="isSuperAdmin"
							v-model="workspace.allowNonTransactionalNumberMapping"
							label="Enable non-transactional mapping API"
							:items="enableOptions"
							item-title="label"
							item-value="value"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
					>
					</v-select>
					<div v-if="workspace.id">
						<div v-if="!workspace.dialerProviderType" class="column-format gap-2">
							<h3>Please select your CX platform</h3>
							<div class="dialer-button" @click="initFive9">
								<img style="max-width: 80px" alt="Five9 logo" src="/images/vendors/five9-logo.png" />
							</div>
							<div class="dialer-button" @click="initAPN">
								<img style="max-width: 200px" alt="APN logo" src="/images/vendors/apn.png" />
							</div>
							<div class="dialer-button" @click="initRING">
								<img style="max-width: 200px" alt="RingCentral logo" src="/images/vendors/ringCentral.png" />
							</div>
							<div class="dialer-button" @click="initNICE">
								<img style="max-width: 150px" alt="NICE logo" src="/images/vendors/nice.png" />
							</div>
							<div class="dialer-button" @click="initPinpoint">
								<img style="max-width: 150px" alt="Connect logo" src="/images/vendors/amazonConnectLogo.png" />
							</div>
							<div class="dialer-button" @click="contactSupport">
								<img style="max-width: 150px" alt="Genesys logo" src="/images/vendors/genesys.png" />
							</div>
							<div class="dialer-button" @click="contactSupport">
								<img style="max-width: 80px" alt="UJet logo" src="/images/vendors/ujet.png" />
							</div>
							<div class="dialer-button" @click="initPrem">
								<span style="font-size: 60px" class="material-symbols-outlined">domain</span>
								<span>Universal connector</span>
							</div>
						</div>
						<div v-else>
							<five9-settings
								ref="dialerProviderSettings"
								v-if="workspace.dialerProviderType === 'FIVE9'"
								:workspace="workspace"
							></five9-settings>
							<apn-settings
								ref="dialerProviderSettings"
								v-if="workspace.dialerProviderType === 'APN'"
								:workspace="workspace"
							></apn-settings>
							<ring-settings
								ref="dialerProviderSettings"
								v-if="workspace.dialerProviderType === 'RING'"
								:workspace="workspace"
							></ring-settings>
							<nice-settings
								ref="dialerProviderSettings"
								v-if="workspace.dialerProviderType === 'NICE'"
								:workspace="workspace"
							></nice-settings>
							<pinpoint-settings
								ref="dialerProviderSettings"
								v-if="workspace.dialerProviderType === 'CONNECT'"
								:workspace="workspace"
							></pinpoint-settings>
							<prem-settings
								ref="dialerProviderSettings"
								v-if="workspace.dialerProviderType === 'PREM'"
								:workspace="workspace"
							></prem-settings>
						</div>
					</div>
				</div>
			</v-form>
			<div class="mt-3 pt-3" style="border-top: 1px solid rgb(var(--v-theme-gray_50))">
				<api-keys class="" :workspace="workspace"></api-keys>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import ConfirmModal from '@/components/ConfirmModal';
	import PDateTime from '@/modules/utils/PDateTime';
	import WorkspaceService from '@/modules/workspaces/WorkspaceService';
	import Five9Settings from '@/modules/dialers/five9/Five9Settings';
	import ApnSettings from '@/modules/dialers/apn/ApnSettings';
	import RingSettings from '@/modules/dialers/ring/RingSettings';
	import NiceSettings from '@/modules/dialers/nice/NiceSettings';
	import ApiKeys from '@/modules/workspaces/ApiKeys';
	import PremSettings from '@/modules/dialers/prem/PremSettings';
	import PinpointSettings from '@/modules/dialers/pinpoint/PinpointSettings.vue';

	export default defineComponent({
		name: 'WorkspaceEdit',
		isRightModal: true,

		props: ['id'],

		components: { PinpointSettings, ApiKeys, NiceSettings, Five9Settings, ApnSettings, RingSettings, PremSettings },

		data: function () {
			return {
				isValid: true,
				workspace: null,
				workspaceService: new WorkspaceService(),
				PDateTime: PDateTime,
				required: [(v) => !!v || 'Name is required'],
				enableOptions: [
					{ label: 'Yes', value: true },
					{ label: 'No', value: false },
				],
				countOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
			};
		},

		mounted() {
			if (this.id) {
				this.getWorkspace();
			} else {
				this.workspace = { id: null, name: null };
				this.$nextTick(() => this.$refs.form.validate());
			}
		},

		beforeUnmount() {},

		methods: {
			async getWorkspace() {
				let result = await this.workspaceService.getWorkspace(this.id);
				this.workspace = result.data;
				this.$store.commit('setSelectedWorkspace', this.workspace.id);
				this.$nextTick(() => this.$refs.form.validate());
			},

			handleClose: function () {
				this.$emit('result', this.workspace);
			},

			async save(close = false) {
				if (this.workspace.id) {
					await this.saveWorkspaceSettings();
				} else {
					await this.initializeWorkspace();
				}

				if (close) {
					this.$emit('result', this.workspace);
				}
			},

			async initializeWorkspace() {
				try {
					this.$store.commit('startLoading');
					let result = await this.workspaceService.initializeWorkspace(this.workspace.name);
					this.workspace = result.data;
					this.syncState();
				} catch (err) {
					this.$store.commit('error', err.response.data.message);
				} finally {
					this.$store.commit('stopLoading');
				}
			},

			async saveWorkspaceSettings() {
				try {
					this.$store.commit('startLoading');
					let settingsSave = await this.$refs.dialerProviderSettings.save();
					if (settingsSave) {
						let result = await this.updateWorkspace();
						this.workspace = result.data;
						this.syncState();
						this.$store.commit('success', 'Workspace settings saved.');
					}
				} catch (err) {
					this.$store.commit('error', err.response.data.message);
				} finally {
					this.$store.commit('stopLoading');
				}
			},

			async updateWorkspace() {
				let updateRequest = {
					name: this.workspace.name,
					allowFullCoverageCampaigns: this.workspace.allowFullCoverageCampaigns,
					maxNumbersPerFullCoverageCampaign: this.workspace.maxNumbersPerFullCoverageCampaign,
					allowNonTransactionalNumberMapping: this.workspace.allowNonTransactionalNumberMapping
				};
				return await this.workspaceService.updateWorkspace(this.workspace.id, updateRequest);
			},

			syncState() {
				this.$store.commit('setSelectedWorkspace', this.workspace.id);
				let ix = this.$store.state.workspaces.findIndex((w) => w.id === this.workspace.id);
				if (ix > -1) {
					this.$store.state.workspaces.splice(ix, 1, this.workspace);
				} else {
					this.$store.state.workspaces.push(this.workspace);
				}
			},

			initFive9() {
				this.workspace.dialerProviderType = 'FIVE9';
				this.workspace.dialerProviderSettings = {
					username: null,
					password: null,
					connected: false,
				};
			},

			initNICE() {
				this.workspace.dialerProviderType = 'NICE';
				this.workspace.dialerProviderSettings = {
					connected: false,
				};
			},

			initPrem() {
				this.workspace.dialerProviderType = 'PREM';
				this.workspace.dialerProviderSettings = {
					connected: true,
				};
			},

			initAPN() {
				this.workspace.dialerProviderType = 'APN';
				this.workspace.dialerProviderSettings = {
					username: null,
					password: null,
					domain: null,
					connected: false,
				};
			},

			initRING() {
				this.workspace.dialerProviderType = 'RING';
				this.workspace.dialerProviderSettings = {
					permanentToken: null,
					subAccountId: null,
					connected: false,
				};
			},

			initPinpoint() {
				this.workspace.dialerProviderType = 'CONNECT';
				this.workspace.dialerProviderSettings = {
					accessKeyId: null,
					secretAccessKey: null,
					awsRegion: null,
					appId: null,
					connected: false,
					attributes: [],
				};
			},

			contactSupport() {
				this.$store.commit('info', 'Please contact your CSM to have this feature enabled');
			},

			confirmDelete: function () {
				let binding = {
					headingText: 'Confirm',
					bodyText: `Are you sure you want to deactivate this workspace?`,
					confirmHint: `Please enter ${this.workspace.name} to continue with deactivate.`,
					confirmText: this.workspace.name,
				};
				this.$store.state.globalModalController
					.openModal(ConfirmModal, binding)
					.then((res) => {
						if (res) {
							this.$store.commit('startLoading');
							this.workspaceService.deactivateWorkspace(this.workspace.id);
							this.$store.commit('success', 'Workspace has been deactivated');
						}
					})
					.finally(() => this.$store.commit('stopLoading'));
			},
		},

		computed: {
			isSuperAdmin: function () {
				return this.$store.state.user.userType === 'SuperAdmin';
			},
		},
	});
</script>

<style scoped lang="scss">
	.desktop {
		min-width: 650px;
		width: 650px;
	}
	#workspace-edit {
		.divider {
			border-top: 1px solid rgb(var(--v-theme-gray_50));
			margin-top: 8px;
			margin-bottom: 8px;
		}

		.logo {
			max-width: 80px;
		}

		.dialer-button {
			padding: 8px;
			border-radius: 4px;
			border: 1px solid rgb(var(--v-theme-gray_50));
			cursor: pointer;
			min-height: 80px;
			background-color: rgb(var(--v-theme-white));
			&:hover {
				border: 1px solid rgb(var(--v-theme-secondary));
			}
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
</style>
