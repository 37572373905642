<template>
	<div style="" class="column-format">
		<v-container style="max-width: 800px; z-index: 100">
			<v-row align="center" justify="center">
				<v-col cols="12">
					<div class="row-format centered">
						<div class="auth-logo"><img alt="logo" src="/images/logo.png" /></div>
					</div>
				</v-col>
				<v-col cols="12" sm="6" md="6" v-if="!showMFA">
					<v-card class="elevation-12">
						<v-toolbar>
							<v-toolbar-title>
								<h2>Sign in</h2>
								<span>Please enter your credentials to continue to the Control Center</span>
							</v-toolbar-title>
						</v-toolbar>
						<v-form ref="form" v-model="valid" lazy-validation @keydown.enter.exact.prevent="signIn()">
							<v-card-text class="column-format gap-3">
								<v-text-field
									name="email"
									autofocus
									v-model="email"
									:rules="emailRules"
									label="Email"
									required
									hide-details
									variant="outlined"
								></v-text-field>
								<v-text-field
									v-model="password"
									:rules="passwordRules"
									name="password"
									label="Password"
									type="password"
									required
									hide-details
									variant="outlined"
								></v-text-field>
								<a href="#" @click.prevent="forgotPassword">Password Reset</a>
							</v-card-text>
							<v-card-actions class="v-col-12">
								<v-btn block color="primary" variant="flat" elevation="0" @click="signIn" :disabled="!valid"
									>Login</v-btn
								>
							</v-card-actions>
						</v-form>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6" md="6" v-if="showMFA">
					<v-card class="elevation-12">
						<v-toolbar>
							<v-toolbar-title>
								<h2>Control Access</h2>
								<span>Please enter your 6 digit code.</span>
							</v-toolbar-title>
						</v-toolbar>
						<v-form ref="form" v-model="valid" lazy-validation @keydown.enter.exact.prevent="secondFactor()">
							<v-card-text>
								<v-text-field
									autofocus
									type="number"
									v-model="mfaCode"
									:rules="mfaCodeRules"
									label="6-digit code"
									required
									hide-details
									variant="outlined"
									@input="checkMfaSubmit"
								></v-text-field>
							</v-card-text>
							<v-card-actions class="v-col-12">
								<v-spacer></v-spacer>
								<v-btn block color="primary" variant="flat" elevation="0" @click="secondFactor" :disabled="!valid"
									>Sign in</v-btn
								>
							</v-card-actions>
						</v-form>
					</v-card>
				</v-col>
			</v-row>
		</v-container>

		<div style="position: absolute; bottom: 0; left: 0; width:100%; background-color: #f5f5f5" class="column-format align-center">
			<div class="row-format py-3" style="width: 700px; min-width: 500px; max-width: 900px; justify-content: space-between">
				<div><img src="images/icon.png" style="max-height: 200px"></div>
				<div class="text-left" style="line-height: 1.5em">
					<span class="footer-header">QUICK LINKS</span><br>
					<a href="https://purecallerid.com/" class="footer-link">Home</a><br>
					<a href="https://purecallerid.com/get-started/" class="footer-link">Get Started</a><br>
					<a href="https://purecallerid.com/platform/" class="footer-link">Platform</a><br>
					<a href="https://purecallerid.com/databoost/" class="footer-link">DataBoost</a><br>
					<a href="https://purecallerid.com/watchtower/" class="footer-link">Watchtower</a><br>
					<a href="https://purecallerid.my.salesforce.com/" class="footer-link">Partners</a>
				</div>
				<div class="text-left" style="line-height: 1.5em">
					<span class="footer-header">CLIENT SUPPORT</span><br>
					<a href="https://purecallerid.com/" class="footer-link">Workspace access</a><br>
					<a href="https://purecallerid.com/get-started/" class="footer-link">Support Portal</a><br>
					<a href="tel:+18447873243" class="footer-link">Call (844) PURE CID</a><br>
					<a href="mailto:contact@purecallerid.com" class="footer-link">Email us</a><br>
				</div>
			</div>

			<div style="background-color: rgb(var(--v-theme-primary)); width:100%; min-height: 45px; color: white" class="column-format centered font-14 brand-medium">
				<div class="">Pure CallerID LLC Copyright © {{DateTime.now().toFormat('yyyy')}}  | <a href="https://www.purecallerid.com/terms-of-service/" target="_blank" style="text-decoration: none; color:white">Terms Of Service</a>  | <a href="https://purecallerid.com/privacy-policy/" target="_blank" style="text-decoration: none; color:white">Privacy Policy</a></div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from '@/modules/auth/AuthService';
	import {DateTime} from 'luxon'

	export default {
		name: 'SignIn',
		data() {
			return {
				DateTime: DateTime,
				email: '',
				emailRules: [(v) => !!v || 'Email is required', (v) => /.+@.+\..+/.test(v) || 'Email must be valid'],
				password: '',
				passwordRules: [(v) => !!v || 'Password is required', (v) => v.length >= 8 || 'Password is too short'],
				valid: false,
				showMFA: false,
				mfaCode: '',
				mfaToken: null,
				mfaCodeRules: [(v) => !!v || 'MFA code is required', (v) => /^\d{6}$/.test(v) || 'MFA code must be 6 digits'],
			};
		},
		methods: {
			forgotPassword() {
				this.$router.push('forgot-password');
			},

			async checkMfaSubmit() {
				if (this.mfaCode.length === 6) {
					await this.secondFactor();
				}
			},

			signIn() {
				if (this.$refs.form.validate()) {
					window.grecaptcha.ready(() => {
						window.grecaptcha
							.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'submit' })
							.then((reCaptchaToken) => {
								this.$store.commit('startLoading');
								AuthService.authWithCredentials(reCaptchaToken, this.email, this.password)
									.then((res) => {
										if (res.type === 'TwoFactorRequired') {
											this.mfaToken = res.token;
											this.showMFA = true;
										} else if (res.type === 'Authenticated') {
											this.processAuthenticationResponse(res.authorizedUser);
										}
									})
									.catch((err) => this.$store.commit('error', err.response.data.message))
									.finally(() => this.$store.commit('stopLoading'));
							});
					});
				}
			},

			async secondFactor() {
				this.$store.commit('startLoading');
				try {
					const response = await AuthService.secondFactor(this.mfaToken, this.mfaCode);
					await this.processAuthenticationResponse(response.authorizedUser);
				} catch (error) {
					console.log(error);
				}
				this.$store.commit('stopLoading');
			},

			async processAuthenticationResponse(authorizedUser) {
				this.$store.commit('loggedIn', authorizedUser);
				try {
					const response = await AuthService.getProfile();
					this.$store.commit('setUser', response);
					this.$router.push('/dashboard');
				} catch (error) {
					this.$store.commit('logOut');
					this.$router.push('/sign-in');
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.footer-header {
		color: rgb(var(--v-theme-secondary));
		font-weight: 500;
	}

	.footer-link {
		color: rgb(var(--v-theme-primary));
		text-decoration: none;
		font-size: 14px;
		&:hover {
			color: rgb(var(--v-theme-secondary));
		}
	}
</style>
