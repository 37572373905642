import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import SignIn from "@/modules/auth/SignIn";
import Loader from "@/modules/auth/Loader";
import RequestReset from "@/modules/auth/RequestReset";
import ResetPassword from "@/modules/auth/ResetPassword";
import TeamRoutes from "@/modules/team/TeamRoutes";
import WorkspaceRoutes from "@/modules/workspaces/WorkspaceRoutes";
import CampaignRoutes from "@/modules/campaigns/CampaignRoutes";
import UtilityRoutes from "@/modules/utilities/UtilityRoutes";
import ActivateAccount from "@/modules/auth/ActivateAccount";
import Token from "@/modules/auth/Token";
import IdentityRoutes from "@/modules/identities/IdentityRoutes";
import PoolRoutes from "@/modules/pools/PoolRoutes";
import LeadRoutes from "@/modules/leads/LeadRoutes";
import ProfileRoutes from "@/modules/profile/ProfileRoutes";
import Dashboard from "@/modules/Dashboard";
import BillingProfile from "@/modules/organization/BillingProfile";
import BillingUsageReport from "@/modules/organization/BillingUsageReport";
import DownloadReports from "@/modules/downloads/DownloadReports";
import WatchTowerConfig from "@/modules/watchTower/WatchTowerConfig";
import Protect from "@/modules/protect/Protect";
import AegisDashboard from "@/modules/aegis/Dashboard";
import CallSearch from "@/modules/aegis/CallSearch";
import RecordSetList from "@/modules/recordSets/RecordSetList";
import WorkflowList from "@/modules/workflow/WorkflowList";
import Webhooks from "@/modules/webhooks/Webhooks";
import UsageReport from "@/modules/usage/UsageReport";

const baseRoutes = [
	{
		path: '/home',
		name: 'home',
		component: Dashboard,
	},
	{
		path: '/sign-in',
		name: 'sign-in',
		component: SignIn,
		meta: {
			insecure: true
		}
	},
	{
		path: '/forgot-password',
		name: 'forgot-password',
		component: RequestReset,
		meta: {
			insecure: true
		}
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		component: ResetPassword,
		props: route => ({
			token: route.query.token
		}),
		meta: {
			insecure: true,
			initState: true,
		}
	},
	{
		path: '/activate-account',
		name: 'activate-account',
		component: ActivateAccount,
		props: route => ({
			token: route.query.token
		}),
		meta: {
			insecure: true,
			initState: true,
		}
	},
	{
		path: '/auth/token',
		name: 'auth-token',
		component: Token,
		props: route => ({
			token: route.query.token
		}),
		meta: {
			insecure: true,
			initState: true,
		}
	},
	{
		path: '/loader',
		name: 'loader',
		component: Loader,
		meta: {
			insecure: true,
			initState: true,
		}
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: Dashboard,
		title: 'Dashboard',
		props: true
	},
	{
		path: '/billing-profile',
		name: 'billing-profile',
		component: BillingProfile,
		title: 'BillingProfile',
		props: true
	},
	{
		path: '/usage/billing',
		name: 'billingUsage',
		component: BillingUsageReport,
		title: 'BillingUsageReport',
		props: true
	},
	{
		path: '/usage/calls',
		name: 'callUsage',
		component: UsageReport,
		title: 'Call usage report',
		props: {
			type: 'voice'
		}
	},
	{
		path: '/usage/messaging',
		name: 'messagingUsage',
		component: UsageReport,
		title: 'Messaging usage report',
		props: {
			type: 'messaging'
		}
	},
	{
		path: '/downloads',
		name: 'downloads',
		component: DownloadReports,
		title: 'DownloadReports',
		props: true
	},
	{
		path: '/watch-tower',
		name: 'watchTower',
		component: WatchTowerConfig,
		title: 'WatchTowerConfig',
		props: true
	},
	{
		path: '/protect',
		name: 'protect',
		component: Protect,
		title: 'Protect',
		props: true
	},
	{
		path: '/aegis-one',
		name: 'aegis-one',
		component: AegisDashboard,
		title: 'AegisDashboard',
		props: true
	},
	{
		path: '/aegis-calls',
		name: 'aegis-calls',
		component: CallSearch,
		title: 'CallSearch',
		props: true
	},
	{
		path: '/record-sets',
		name: 'record-sets',
		component: RecordSetList,
		title: 'RecordSetList',
		props: true
	},
	{
		path: '/workflows',
		name: 'workflows',
		component: WorkflowList,
		title: 'WorkflowList',
		props: true
	},
	{
		path: '/webhooks',
		name: 'webhooks',
		component: Webhooks,
		title: 'Webhooks',
		props: true
	},
];

const routes = baseRoutes.concat(
	TeamRoutes,
	WorkspaceRoutes,
	CampaignRoutes,
	IdentityRoutes,
	PoolRoutes,
	LeadRoutes, 
	UtilityRoutes,
	ProfileRoutes
);

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routes,
});

router.beforeEach((to, from, next) => {
	if (!store.state.initialized) {
		if (to.meta.initState) {
			next();
		} else {
			store.commit('setOrigRequestedUri', to.fullPath);
			next('/loader');
		}
	} else {
		if (store.state.authenticated || to.meta.insecure) {
			next();
		} else {
			next('/sign-in');
		}
	}
});

export default router;