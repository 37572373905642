import { createStore } from 'vuex';
import CustomEventBus from "@/modules/utils/CustomEventBus";
import WorkspaceService from "@/modules/workspaces/WorkspaceService";
import PubNub from 'pubnub';

const store = createStore({
    state: {
        authUrl: process.env.VUE_APP_BASE_AUTH_URL,
        apiUrl: process.env.VUE_APP_BASE_API_URL,
        initialized: false,
        authenticated: false,
        accessToken: null,
        user: null,
        leftNavExpanded: true,
        notificationExpanded: false,
        rightModalCount: 0,
        eventBus: new CustomEventBus(),
        alertMessage: null,
        loadingState: false,
        loadingMessage: null,
        selectedApp: 'Core',
        origRequestedUri: '/dashboard',
        weekStartsOn: 'Sunday',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        selectedWorkspace: 0,
        workspaces: [],
        pubNub: null,
        pubNubToken: null,
        pubNubChannels: [],
        notifications: [],
        oAuthProcessed: false,
    },

    getters: {
        getBaseUrl: (state) => {
            if(state.user){
                return state.apiUrl + '/' + state.user.orgId;
            }else{
                return null;
            }
        },

        unreadNotificationCount: (state) => {
            let unreadCount = 0;

            for (let i = 0; i < state.notifications.length; i++) {
                let notification = state.notifications[i];
                if (notification.read === false) {
                    unreadCount++;
                }
            }

            return unreadCount;
        },

        getOrgId: (state) => {
            if(state.user) {
                return state.user.orgId;
            }else{
                return null;
            }
        },

        getSelectedWorkspace: (state) => {
            return state.workspaces.find(w => w.id === state.selectedWorkspace)
        }
    },

    mutations: {
        initialized(state, initialized){
            state.initialized = initialized;
        },

        setOrigRequestedUri(state, origRequestedUri) {
            state.origRequestedUri = origRequestedUri;
        },

        loggedIn(state, authorizedUser) {
            state.accessToken = authorizedUser.authToken;
            state.pubNubToken = authorizedUser.pubNubToken;

            if(state.pubNub){
                state.pubNub.setToken(state.pubNubToken);
                state.pubNub.subscriptionSet({
                    channels: state.pubNubChannels,
                }).subscribe();
            }

            try {
                let root = document.documentElement;
                root.style.setProperty("--v-recaptcha-visibility", 'hidden');
            }catch(err){console.log(err);}
        },

        setUser(state, user) {
            state.user = user;
            state.authenticated = true;

            new WorkspaceService().getWorkspaces().then((res) => {
                let data = res.data.filter(w => w.status === 'ACTIVE');
                state.workspaces.replace(data);
                let localStorageValue = localStorage.getItem('SELECTED_WORKSPACE');
                if(localStorageValue) {
                    state.selectedWorkspace = parseInt(localStorageValue,10);
                }else if(!state.selectedWorkspace && state.workspaces.length){
                    state.selectedWorkspace = state.workspaces[0].id;
                }
            })

            if(state.pubNub == null) {
                state.pubNub = new PubNub({
                    subscribeKey: process.env.VUE_APP_PUBNUB_SUBSCRIBE,
                    publishKey: process.env.VUE_APP_PUBNUB_PUBLISH,
                    uuid: user.id,
                    logVerbosity: false,
                    auto_reconnect: true,
                    restore: true,
                });

                state.pubNub.setToken(state.pubNubToken);
                state.pubNub.setHeartbeatInterval(60);
                state.pubNubChannels = [];
                state.pubNubChannels.push('u-' + user.id + '.*');
                state.pubNubChannels.push('o-' + user.orgId + '.*');

                // add a status listener
                state.pubNub.addListener({
                    status: (s) => {console.log('Status', s) }
                });

                state.pubNub.addListener({
                    message: function (msg) {
                        state.eventBus.$emit(msg.channel, msg); //broadcast on original channel

                        let parts = msg.channel.split('.');
                        let channel = '';
                        for (let i = 0; i < parts.length; i++) {
                            if (channel === '') {
                                channel = parts[i];
                            } else {
                                channel = channel + '.' + parts[i];
                            }

                            if (channel !== msg.channel) {
                                state.eventBus.$emit(channel, msg);
                            }

                            let truncated = channel.split('-');
                            if (truncated.length > 1) {
                                truncated.pop();
                                truncated = truncated.join('-');
                                state.eventBus.$emit(truncated, msg);
                            }
                        }
                    },
                });

                state.pubNub.subscriptionSet({
                    channels: state.pubNubChannels,
                }).subscribe();
            }

            state.eventBus.$emit('logged-in',user);
        },

        logOut(state) {
            state.accessToken = null;
            state.authenticated = false;
            state.user = null;
            sessionStorage.clear();

            try {
                let root = document.documentElement;
                root.style.setProperty("--v-recaptcha-visibility", 'visible');
            }catch(err){console.log(err);}
        },

        setSelectedWorkspace(state,selectedWorkspaceId){
            localStorage.setItem('SELECTED_WORKSPACE',selectedWorkspaceId);
            state.selectedWorkspace = selectedWorkspaceId;
            state.eventBus.$emit('workspace-changed',selectedWorkspaceId);
        },

        toggleLeftNav(state){
            state.leftNavExpanded = !state.leftNavExpanded
        },

        toggleNotifications(state){
            state.notificationExpanded = !state.notificationExpanded
        },

        success(state, message) {
            state.alertMessage = {
                type: 'success',
                message: message,
            };
        },

        info(state, message) {
            state.alertMessage = {
                type: 'info',
                message: message,
            };
        },

        warn(state, message) {
            state.alertMessage = {
                type: 'warning',
                message: message,
            };
        },

        error(state, message) {
            state.alertMessage = {
                type: 'error',
                message: message,
            };
        },

        startLoading(state,message=null) {
            state.loadingState = true;
            state.loadingMessage = message;
        },

        stopLoading(state) {
            state.loadingState = false;
            state.loadingMessage = null;
        },
    }
});

export default store;