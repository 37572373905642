<template>
	<v-container>
		<v-row align="center" justify="center">
			<v-col cols="12">
				<div class="row-format centered">
					<div class="auth-logo"><img alt="logo" src="/images/logo.png" /></div>
				</div>
			</v-col>
			<v-col cols="12" sm="6" md="6" v-if="showError">
				<v-card class="elevation-12">
					<v-toolbar-title>
						<h2>Activate Account</h2>
						<span>This account activation link is no longer valid.</span>
					</v-toolbar-title>
				</v-card>
			</v-col>
			<v-col cols="12" sm="6" md="6" v-else>
				<v-card class="elevation-12" style="white-space: normal!important;">
					<div class="my-3">
						<h2>Activate Account</h2>
						<div>Please enter a password.</div>
					</div>
					<v-form ref="form" v-model="valid" lazy-validation @keydown.enter.exact.prevent="activateAccount()">
						<v-card-text class="column-format gap-3">
							<v-text-field
								autofocus
								type="password"
								v-model="password"
								:rules="passwordRules"
								label="Password"
								required
								variant="outlined"
								hide-details
							></v-text-field>
							<v-text-field
								autofocus
								type="password"
								v-model="confirmPassword"
								:rules="confirmPasswordRules"
								label="Confirm password"
								required
								variant="outlined"
								hide-details
							></v-text-field>
							<!--maz-phone-number-input
								:translations="{ phoneInput: { placeholder: 'MFA Phone', example: 'MFA Phone: ' } }"
								:only-countries="['US', 'CA', 'EC']"
								@update="handlePhoneInput"
								v-model="phone"
							>
							</maz-phone-number-input-->
						</v-card-text>
						<v-card-actions class="v-col-12">
							<v-btn block color="primary" variant="flat" @click="activateAccount" :disabled="!valid">Activate account</v-btn>
						</v-card-actions>
					</v-form>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import AuthService from '@/modules/auth/AuthService';

	export default {
		name: 'RequestReset',

		components: { },

		props: ['token'],

		data() {
			return {
				password: '',
				confirmPassword: '',
				showError: false,

				passwordRules: AuthService.getPasswordRules(),

				confirmPasswordRules: [
					(v) => !!v || 'Confirm password is required',
					(v) => v === this.confirmPassword || 'Passwords do not match',
				],

				valid: false,
			};
		},

		async mounted() {
			await this.verifyToken();
		},

		methods: {
			async verifyToken() {
				try {
					await AuthService.activationTokenVerify(this.token);
					this.isReady = true
				} catch (error) {
					this.showError = true
				}
			},

			handlePhoneInput: function (event) {
				this.phoneValid = event.isValid;
			},

			activateAccount: function () {
				if (!this.valid) {
					return;
				}
				this.$store.commit('startLoading');
				AuthService.activateAccount(this.token, this.password)
					.then((res) => {
						this.processAuthenticationResponse(res.authorizedUser);
					})
					.catch(() => (this.showError = true))
					.finally(() => this.$store.commit('stopLoading'));
			},

			processAuthenticationResponse(authorizedUser) {
				this.$store.commit('loggedIn', authorizedUser)

				AuthService.getProfile()
					.then((res) => {
						console.log(res);
						this.$store.commit('setUser', res)
						this.$router.push({path: '/home', replace: true});
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('logOut');
						this.$router.push('/sign-in');
					});
			},
		},
		computed: {},
	};
</script>
