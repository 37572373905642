<template>
	<div style="width: 100%; height: 100%; position: relative" class="row-format">
		<div style="width: 300px; height: 100%; max-height: calc(100vh - 75px); overflow-y: auto; background-color: rgb(var(--v-theme-white))" class="pa-2 column-format gap-1">
			<div
				v-for="node in nodeType"
				:key="node.value"
				draggable="true"
				:id="node.value"
				class="row-format node-type pa-1 gap-2"
				@drag="drag"
			>
				<p-icon
					class="material-symbols-outlined"
					v-if="node.icon"
					:color="node.color ? node.color : 'black'"
					:size="24"
					>{{ node.icon }}</p-icon
				>
				<img v-else-if="node.logo" :src="`/images/vendors/${node.logo}`" style="max-width: 24px" />
				<div class="column-format text-left">
					<div class="font-14">{{ node.label }}</div>
					<div class="font-12 mt-n1 font-gray_70">{{ node.description }}</div>
				</div>
			</div>
		</div>
		<div
			id="drawflow"
			@dragover="allowDrop"
			@drop="drop"
			style="display: block; position: relative; width: calc(100% - 200px); height: 100%"
		></div>
	</div>
</template>

<script>
	import {
		defineComponent,
		h,
		render,
		ref,
		computed,
		toRefs,
		getCurrentInstance,
		shallowRef,
		onMounted,
		onBeforeUnmount,
	} from 'vue';
	import { useStore } from 'vuex';

	import DrawFlowExtended from '@/modules/workflow/DrawFlowExtended';

	import Test from '@/modules/workflow/nodes/Test';
	import Start from '@/modules/workflow/nodes/Start';
	import 'drawflow/dist/drawflow.min.css';
	import PIcon from '@/components/PIcon';
	import Reject from '@/modules/workflow/nodes/Reject';
	import Accept from '@/modules/workflow/nodes/Accept';
	import Return from "@/modules/workflow/nodes/Return";
	import DataBoost from '@/modules/workflow/nodes/DataBoost';
	import CallerId from '@/modules/workflow/nodes/CallerId';
	import Decision from '@/modules/workflow/nodes/Decision';
	import ActiveProspect from '@/modules/workflow/nodes/ActiveProspect';
	import Verisk from '@/modules/workflow/nodes/Verisk';
	import DoNotCall from '@/modules/workflow/nodes/DoNotCall';
	import RecordSet from '@/modules/workflow/nodes/RecordSet';
	import StateDialing from '@/modules/workflow/nodes/StateDialing';
	import TimeOfDay from '@/modules/workflow/nodes/TimeOfDay';
	import Velocity from '@/modules/workflow/nodes/Velocity';
	import KnownLitigator from "@/modules/workflow/nodes/KnownLitigator";
	import StateDoNotCall from "@/modules/workflow/nodes/StateDoNotCall";
	import StateOfEmergency from "@/modules/workflow/nodes/StateOfEmergency";
	import Webhook from "@/modules/workflow/nodes/Webhook";
	import WorkflowTransfer from "@/modules/workflow/nodes/WorkflowTransfer";
	import ReassignedNumberDatabase from "@/modules/workflow/nodes/ReassignedNumberDatabase";

	export default defineComponent({
		name: 'Workflow',

		props: ['workflow'],

		components: {
			PIcon,
		},

		setup(props, context) {
			const editor = shallowRef({});
			const Vue = ref({});
			const internalInstance = getCurrentInstance();
			const store = useStore();
			const { workflow } = toRefs(props);
			let currentDragType = null;

			// Lifecycle hook
			onMounted(() => {
				Vue.value = { version: 3, h, render };
				internalInstance.appContext.app._context.config.globalProperties.$df = editor;

				let id = document.getElementById('drawflow');
				editor.value = new DrawFlowExtended(id, Vue.value, internalInstance.appContext.app._context);
				editor.value.reroute = true;
				editor.value.reroute_fix_curvature = true;
				editor.value.useuuid = true;
				//editor.value.editor_mode = 'view';
				editor.value.start();

				editor.value.on('connectionCreated', (info) => {
					const nodeInfo = editor.value.getNodeFromId(info.output_id);
					if (nodeInfo.outputs[info.output_class].connections.length > 1) {
						const removeConnectionInfo = nodeInfo.outputs[info.output_class].connections[0];
						editor.value.removeSingleConnection(
							info.output_id,
							removeConnectionInfo.node,
							info.output_class,
							removeConnectionInfo.output
						);
					}
				});

				editor.value.on('nodeRemoved', (id) => {
					context.emit('node-removed', id);
				});

				editor.value.registerNode('Start', Start, {}, {});
				editor.value.registerNode('Accept', Accept, {}, {});
				editor.value.registerNode('Reject', Reject, {}, {});
				editor.value.registerNode('Return', Return, {}, {});
				editor.value.registerNode('DataBoost', DataBoost, {}, {});
				editor.value.registerNode('CallerId', CallerId, {}, {});
				editor.value.registerNode('Decision', Decision, {}, {});
				editor.value.registerNode('ActiveProspect', ActiveProspect, {}, {});
				editor.value.registerNode('Verisk', Verisk, {}, {});
				editor.value.registerNode('DoNotCall', DoNotCall, {}, {});
				editor.value.registerNode('RecordSet', RecordSet, {}, {});
				editor.value.registerNode('StateDialing', StateDialing, {}, {});
				editor.value.registerNode('TimeOfDay', TimeOfDay, {}, {});
				editor.value.registerNode('Velocity', Velocity, {}, {});
				editor.value.registerNode('KnownLitigator', KnownLitigator, {}, {});
				editor.value.registerNode('StateDoNotCall', StateDoNotCall, {}, {});
				editor.value.registerNode('StateOfEmergency', StateOfEmergency, {}, {});
				editor.value.registerNode('Webhook', Webhook, {}, {});
				editor.value.registerNode('WorkflowTransfer', WorkflowTransfer, {}, {});
				editor.value.registerNode('ReassignedNumberDatabase', ReassignedNumberDatabase, {}, {});
				editor.value.registerNode('Test', Test, {}, {});

				store.commit('startLoading');
				setTimeout(() => {
					initialize();
					store.commit('stopLoading');
				}, 1000);

				window.addEventListener('resize', reDrawConnections);
				store.state.eventBus.$on('workflow-resize', reDrawConnections);
			});

			onBeforeUnmount(() => {
				window.removeEventListener('resize', reDrawConnections);
				store.state.eventBus.$off('workflow-resize', reDrawConnections);
			});

			const initialize = () => {
				importWorkflow();
			};

			const importWorkflow = () => {
				if (workflow && workflow.value) {
					editor.value.import(workflow.value);
					setTimeout(() => reDrawConnections(), 50);
				} else {
					currentDragType = 'Start';
					addNode({ clientX: 300, clientY: 200 });
				}
			};

			const allowDrop = (ev) => {
				ev.preventDefault();
			};

			const drag = (ev) => {
				currentDragType = ev.currentTarget.id;
			};

			const drop = (ev) => {
				ev.preventDefault();
				addNode(ev);
			};

			const reDrawConnections = () => {
				for (const [key] of Object.entries(editor.value.drawflow.drawflow['Home'].data)) {
					editor.value.updateConnectionNodes('node-' + key);
				}
			};

			const doExport = () => {
				return editor.value.export();
			};

			const findHome = () => {
				const df = document.getElementById('drawflow').firstChild;
				let startNode = df.querySelector('.Start');
				startNode.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
			};

			const zoomIn = () => {
				editor.value.zoom_in();
			}

			const zoomOut = () => {
				editor.value.zoom_out();
			}

			const addNode = (event) => {
				const rect = document.getElementById('drawflow').getBoundingClientRect();
				const df = document.getElementById('drawflow').firstChild.getBoundingClientRect();

				let left = df.left - rect.left;
				let top = df.top - rect.top;

				const x = event.clientX - rect.left - left;
				const y = event.clientY - rect.top - top;

				let component;

				if (currentDragType === 'Start') {
					component = Start;
				} else if (currentDragType === 'Accept') {
					component = Accept;
				} else if (currentDragType === 'Reject') {
					component = Reject;
				} else if (currentDragType === 'Return') {
					component = Return;
				} else if (currentDragType === 'DataBoost') {
					component = DataBoost;
				} else if (currentDragType === 'CallerId') {
					component = CallerId;
				} else if (currentDragType === 'Decision') {
					component = Decision;
				} else if (currentDragType === 'ActiveProspect') {
					component = ActiveProspect;
				} else if (currentDragType === 'Verisk') {
					component = Verisk;
				} else if (currentDragType === 'DoNotCall') {
					component = DoNotCall;
				} else if (currentDragType === 'RecordSet') {
					component = RecordSet;
				} else if (currentDragType === 'StateDialing') {
					component = StateDialing;
				} else if (currentDragType === 'TimeOfDay') {
					component = TimeOfDay;
				} else if (currentDragType === 'Velocity') {
					component = Velocity;
				} else if (currentDragType === 'KnownLitigator') {
					component = KnownLitigator;
				} else if (currentDragType === 'StateDoNotCall') {
					component = StateDoNotCall;
				} else if (currentDragType === 'StateOfEmergency') {
					component = StateOfEmergency;
				} else if (currentDragType === 'Webhook') {
					component = Webhook;
				} else if (currentDragType === 'WorkflowTransfer') {
					component = WorkflowTransfer;
				} else if (currentDragType === 'ReassignedNumberDatabase') {
					component = ReassignedNumberDatabase;
				} else {
					component = Test;
				}

				let node = editor.value.addNode(
					currentDragType,
					component.inputs,
					component.outputs,
					x,
					y,
					currentDragType,
					{},
					currentDragType,
					'vue'
				);

				context.emit('node-added', { type: currentDragType, id: node });
			};

			const nodeType = computed(() => {
				return [
					{
						icon: 'check_circle',
						color: 'success',
						label: 'Accept',
						description: 'Allow call to proceed to network',
						value: 'Accept',
					},
					{
						icon: 'block',
						label: 'Reject',
						color: 'error',
						description: 'Reject call attempt',
						value: 'Reject',
					},
					{
						icon: 'move_up',
						label: 'Return',
						color: 'secondary',
						description: 'Returns control from an embedded workflow',
						value: 'Return',
					},
					{
						icon: 'move_down',
						label: 'Workflow handoff',
						color: '#3d85c6',
						description: 'Handoff control to a different Aegis workflow',
						value: 'WorkflowTransfer',
					},
					{
						icon: 'rocket',
						label: 'DataBoost',
						color: '#1F4E79',
						description: 'Perform DataBoost lookup query',
						value: 'DataBoost',
					},
					{
						icon: 'campaign',
						label: 'Pure CallerId',
						color: 'secondary',
						description: 'Real time Pure CallerId campaign integration',
						value: 'CallerId',
					},
					{
						icon: 'database',
						label: 'Record set query',
						color: '#FF7043',
						description: 'Query Aegis Record set',
						value: 'RecordSet',
					},
					{
						icon: 'account_tree',
						label: 'Decision',
						color: '#6F42C1',
						description: 'Conditional branching based on call data',
						value: 'Decision',
					},
					{
						icon: 'phone_locked',
						label: 'FTC Do-Not-Call',
						color: '#9B111E',
						description: 'Check national Do-Not-Call database',
						value: 'DoNotCall',
					},
					{
						icon: 'phone_locked',
						label: 'State Do-Not-Call',
						color: '#0096FF',
						description: 'Check state Do-Not-Call database',
						value: 'StateDoNotCall',
					},
					{
						icon: 'numbers',
						label: 'RND Query',
						color: '#373f51',
						description: 'Check reassigned number database',
						value: 'ReassignedNumberDatabase',
					},
					{
						icon: 'emergency',
						label: 'Natural disaster',
						color: '#EE4B2B',
						description: 'Real time national disaster monitor',
						value: 'StateOfEmergency',
					},
					{
						icon: 'balance',
						label: 'Known litigator',
						color: '#1F4E79',
						description: 'Prevent calls to known litigators',
						value: 'KnownLitigator',
					},
					{
						icon: 'gavel',
						label: 'State dialing rules',
						color: '#008080',
						description: 'Ensure compliance with state based time of day and day of week dialing',
						value: 'StateDialing',
					},
					{
						icon: 'schedule',
						label: 'Time of day',
						color: '#FFBF00',
						description: 'Verify custom time of day and day of week dialing rules',
						value: 'TimeOfDay',
					},
					{
						icon: 'speed',
						label: 'Velocity',
						color: '#009B77',
						description: 'Control maximum calls to a number within a time period',
						value: 'Velocity',
					},
					{
						icon: 'webhook',
						label: 'Webhook',
						color: '#6aa84f',
						description: 'Trigger pre-configured workspace webhook with Aegis call data',
						value: 'Webhook',
					},

					{
						logo: 'active-prospect.svg',
						label: 'Active prospect',
						description: 'Verify consent with ActiveProspect token',
						value: 'ActiveProspect',
					},
					{
						logo: 'verisk.svg',
						label: 'Verisk',
						description: 'Verify consent with Verisk token',
						value: 'Verisk',
					},
				];
			});

			// Make handleClick available to the template
			return {
				initialize,
				importWorkflow,
				allowDrop,
				drag,
				drop,
				reDrawConnections,
				doExport,
				findHome,
				addNode,
				nodeType,
				zoomIn,
				zoomOut
			};
		},
	});
</script>

<style scoped lang="scss">
	.node-type {
		cursor: grab;
		border-radius: 4px;
		border: 1px solid rgb(var(--v-theme-gray_30));
		&:active {
			cursor: grabbing;
		}
		&:hover {
			background-color: rgb(var(--v-theme-gray_10));
		}
	}
</style>

<style lang="scss">
	.workflow-node-parent {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;
	}

	.workflow-node-label {
		white-space: nowrap;
		border: 1px solid black;
		background-color: #FFFCB3;
		position: absolute;
		bottom: -50px;
		padding: 4px;
		border-radius: 4px;
		font-size: 12px;
		left: 50%;
		transform: translateX(-50%);
	}

	.workflow-node-label-options {
		@extend .workflow-node-label;
		bottom: -53px!important;
	}

	.connection {
		left: 0;
		top: 0;
	}

	.drawflow-node.Start .drawflow-delete {
		display: none;
	}

	.first-label {
		display: block;
		position: absolute;
		right: 10px;
		top: -10px;
		font-size: 12px;
		font-weight: 600;
		color: rgb(var(--v-theme-white));
		background-color: rgb(var(--v-theme-secondary));
		border-radius: 4px;
		padding: 0px 4px;
	}

	.second-label {
		display: block;
		position: absolute;
		right: 10px;
		top: -2px;
		font-size: 12px;
		font-weight: 600;
		color: rgb(var(--v-theme-white));
		background-color: rgb(var(--v-theme-secondary));
		border-radius: 4px;
		padding: 0px 4px;
	}

	.drawflow-node.Decision .outputs .output:nth-child(1):before {
		@extend .first-label;
		content: 'Yes';
	}

	.drawflow-node.Decision .outputs .output:nth-child(2):before {
		@extend .second-label;
		content: 'No';
	}

	.drawflow-node.ReassignedNumberDatabase .outputs .output:nth-child(1):before {
		@extend .first-label;
		content: 'OK';
	}

	.drawflow-node.ReassignedNumberDatabase .outputs .output:nth-child(2):before {
		@extend .second-label;
		content: 'Reassigned';
	}

	.drawflow-node.DoNotCall .outputs .output:nth-child(1):before {
		@extend .first-label;
		content: 'OK';
	}

	.drawflow-node.DoNotCall .outputs .output:nth-child(2):before {
		@extend .second-label;
		content: 'Listed';
	}

	.drawflow-node.KnownLitigator .outputs .output:nth-child(1):before {
		@extend .first-label;
		content: 'OK';
	}

	.drawflow-node.KnownLitigator .outputs .output:nth-child(2):before {
		@extend .second-label;
		content: 'Listed';
	}

	.drawflow-node.StateDialing .outputs .output:nth-child(1):before {
		@extend .first-label;
		content: 'OK';
	}

	.drawflow-node.StateDialing .outputs .output:nth-child(2):before {
		@extend .second-label;
		content: 'Blocked';
	}

	.drawflow-node.TimeOfDay .outputs .output:nth-child(1):before {
		@extend .first-label;
		content: 'OK';
	}

	.drawflow-node.TimeOfDay .outputs .output:nth-child(2):before {
		@extend .second-label;
		content: 'Blocked';
	}

	.drawflow-node.Velocity .outputs .output:nth-child(1):before {
		@extend .first-label;
		content: 'OK';
	}

	.drawflow-node.Velocity .outputs .output:nth-child(2):before {
		@extend .second-label;
		content: 'Blocked';
	}

	.drawflow-node.Verisk .outputs .output:nth-child(1):before {
		@extend .first-label;
		content: 'OK';
	}

	.drawflow-node.Verisk .outputs .output:nth-child(2):before {
		@extend .second-label;
		content: 'Blocked';
	}

	.drawflow-node.ActiveProspect .outputs .output:nth-child(1):before {
		@extend .first-label;
		content: 'OK';
	}

	.drawflow-node.ActiveProspect .outputs .output:nth-child(2):before {
		@extend .second-label;
		content: 'Blocked';
	}

	.drawflow-node.StateDoNotCall .outputs .output:nth-child(1):before {
		@extend .first-label;
		content: 'OK';
	}

	.drawflow-node.StateDoNotCall .outputs .output:nth-child(2):before {
		@extend .second-label;
		content: 'Listed';
	}

	.drawflow-node.StateOfEmergency .outputs .output:nth-child(1):before {
		@extend .first-label;
		content: 'OK';
	}

	.drawflow-node.StateOfEmergency .outputs .output:nth-child(2):before {
		@extend .second-label;
		content: 'Active';
	}

	:root {
		--dfBackgroundColor: rgb(var(--v-theme-white));
		--dfBackgroundSize: 20px;
		--dfBackgroundImage: linear-gradient(to right, rgb(var(--v-theme-gray_10)) 1px, transparent 1px),
			linear-gradient(to bottom, rgb(var(--v-theme-gray_10)) 1px, transparent 1px);

		--dfNodeType: flex;
		--dfNodeTypeFloat: none;
		--dfNodeBackgroundColor: rgb(var(--v-theme-white));
		--dfNodeTextColor: rgb(var(--v-theme-black));
		--dfNodeBorderSize: 1px;
		--dfNodeBorderColor: rgb(var(--v-theme-secondary));
		--dfNodeBorderRadius: 4px;
		--dfNodeMinHeight: 40px;
		--dfNodeMinWidth: 50px;
		--dfNodePaddingTop: 12px;
		--dfNodePaddingBottom: 12px;
		--dfNodeBoxShadowHL: 0px;
		--dfNodeBoxShadowVL: 0px;
		--dfNodeBoxShadowBR: 0px;
		--dfNodeBoxShadowS: 0px;
		--dfNodeBoxShadowColor: rgb(var(--v-theme-white));

		--dfNodeHoverBackgroundColor: rgb(var(--v-theme-white));
		--dfNodeHoverTextColor: rgb(var(--v-theme-black));
		--dfNodeHoverBorderSize: 1px;
		--dfNodeHoverBorderColor: rgb(var(--v-theme-primary));
		--dfNodeHoverBorderRadius: 4px;

		--dfNodeHoverBoxShadowHL: 0px;
		--dfNodeHoverBoxShadowVL: 0px;
		--dfNodeHoverBoxShadowBR: 4px;
		--dfNodeHoverBoxShadowS: 2px;
		--dfNodeHoverBoxShadowColor: rgb(var(--v-theme-gray_30));

		--dfNodeSelectedBackgroundColor: rgb(var(--v-theme-white));
		--dfNodeSelectedTextColor: rgb(var(--v-theme-black));
		--dfNodeSelectedBorderSize: 1px;
		--dfNodeSelectedBorderColor: rgb(var(--v-theme-primary));
		--dfNodeSelectedBorderRadius: 4px;

		--dfNodeSelectedBoxShadowHL: 0px;
		--dfNodeSelectedBoxShadowVL: 0px;
		--dfNodeSelectedBoxShadowBR: 4px;
		--dfNodeSelectedBoxShadowS: 2px;
		--dfNodeSelectedBoxShadowColor: rgb(var(--v-theme-gray_30));

		--dfInputBackgroundColor: rgb(var(--v-theme-white));
		--dfInputBorderSize: 1px;
		--dfInputBorderColor: rgb(var(--v-theme-secondary));
		--dfInputBorderRadius: 50px;
		--dfInputLeft: -27px;
		--dfInputHeight: 10px;
		--dfInputWidth: 10px;

		--dfInputHoverBackgroundColor: rgb(var(--v-theme-white));
		--dfInputHoverBorderSize: 1px;
		--dfInputHoverBorderColor: rgb(var(--v-theme-secondary));
		--dfInputHoverBorderRadius: 50px;

		--dfOutputBackgroundColor: rgb(var(--v-theme-white));
		--dfOutputBorderSize: 1px;
		--dfOutputBorderColor: rgb(var(--v-theme-secondary));
		--dfOutputBorderRadius: 50px;
		--dfOutputRight: -16px;
		--dfOutputHeight: 10px;
		--dfOutputWidth: 10px;

		--dfOutputHoverBackgroundColor: rgb(var(--v-theme-white));
		--dfOutputHoverBorderSize: 1px;
		--dfOutputHoverBorderColor: rgb(var(--v-theme-primary));
		--dfOutputHoverBorderRadius: 50px;

		--dfLineWidth: 2px;
		--dfLineColor: rgb(var(--v-theme-secondary));
		--dfLineHoverColor: rgb(var(--v-theme-primary));
		--dfLineSelectedColor: rgb(var(--v-theme-primary));

		--dfRerouteBorderWidth: 2px;
		--dfRerouteBorderColor: rgb(var(--v-theme-primary));
		--dfRerouteBackgroundColor: rgb(var(--v-theme-white));

		--dfRerouteHoverBorderWidth: 2px;
		--dfRerouteHoverBorderColor: rgb(var(--v-theme-primary));
		--dfRerouteHoverBackgroundColor: rgb(var(--v-theme-white));

		--dfDeleteDisplay: block;
		--dfDeleteColor: rgb(var(--v-theme-white));
		--dfDeleteBackgroundColor: rgb(var(--v-theme-error));
		--dfDeleteBorderSize: 1px;
		--dfDeleteBorderColor: rgb(var(--v-theme-white));
		--dfDeleteBorderRadius: 50px;
		--dfDeleteTop: -15px;

		--dfDeleteHoverColor: rgb(var(--v-theme-primary));
		--dfDeleteHoverBackgroundColor: rgb(var(--v-theme-error));
		--dfDeleteHoverBorderSize: 1px;
		--dfDeleteHoverBorderColor: rgb(var(--v-theme-primary));
		--dfDeleteHoverBorderRadius: 50px;
	}

	#drawflow {
		background: var(--dfBackgroundColor);
		background-size: var(--dfBackgroundSize) var(--dfBackgroundSize);
		background-image: var(--dfBackgroundImage);
	}

	.drawflow .drawflow-node {
		display: var(--dfNodeType);
		background: var(--dfNodeBackgroundColor);
		color: var(--dfNodeTextColor);
		border: var(--dfNodeBorderSize) solid var(--dfNodeBorderColor);
		border-radius: var(--dfNodeBorderRadius);
		min-height: var(--dfNodeMinHeight);
		width: auto;
		min-width: var(--dfNodeMinWidth);
		padding-top: var(--dfNodePaddingTop);
		padding-bottom: var(--dfNodePaddingBottom);
		-webkit-box-shadow: var(--dfNodeBoxShadowHL) var(--dfNodeBoxShadowVL) var(--dfNodeBoxShadowBR) var(--dfNodeBoxShadowS)
			var(--dfNodeBoxShadowColor);
		box-shadow: var(--dfNodeBoxShadowHL) var(--dfNodeBoxShadowVL) var(--dfNodeBoxShadowBR) var(--dfNodeBoxShadowS)
			var(--dfNodeBoxShadowColor);
	}

	.drawflow .drawflow-node:hover {
		background: var(--dfNodeHoverBackgroundColor);
		color: var(--dfNodeHoverTextColor);
		border: var(--dfNodeHoverBorderSize) solid var(--dfNodeHoverBorderColor);
		border-radius: var(--dfNodeHoverBorderRadius);
		-webkit-box-shadow: var(--dfNodeHoverBoxShadowHL) var(--dfNodeHoverBoxShadowVL) var(--dfNodeHoverBoxShadowBR)
			var(--dfNodeHoverBoxShadowS) var(--dfNodeHoverBoxShadowColor);
		box-shadow: var(--dfNodeHoverBoxShadowHL) var(--dfNodeHoverBoxShadowVL) var(--dfNodeHoverBoxShadowBR)
			var(--dfNodeHoverBoxShadowS) var(--dfNodeHoverBoxShadowColor);
	}

	.drawflow .drawflow-node.selected {
		background: var(--dfNodeSelectedBackgroundColor);
		color: var(--dfNodeSelectedTextColor);
		border: var(--dfNodeSelectedBorderSize) solid var(--dfNodeSelectedBorderColor);
		border-radius: var(--dfNodeSelectedBorderRadius);
		-webkit-box-shadow: var(--dfNodeSelectedBoxShadowHL) var(--dfNodeSelectedBoxShadowVL) var(--dfNodeSelectedBoxShadowBR)
			var(--dfNodeSelectedBoxShadowS) var(--dfNodeSelectedBoxShadowColor);
		box-shadow: var(--dfNodeSelectedBoxShadowHL) var(--dfNodeSelectedBoxShadowVL) var(--dfNodeSelectedBoxShadowBR)
			var(--dfNodeSelectedBoxShadowS) var(--dfNodeSelectedBoxShadowColor);
	}

	.drawflow .drawflow-node .input {
		left: var(--dfInputLeft);
		background: var(--dfInputBackgroundColor);
		border: var(--dfInputBorderSize) solid var(--dfInputBorderColor);
		border-radius: var(--dfInputBorderRadius);
		height: var(--dfInputHeight);
		width: var(--dfInputWidth);
	}

	.drawflow .drawflow-node .input:hover {
		background: var(--dfInputHoverBackgroundColor);
		border: var(--dfInputHoverBorderSize) solid var(--dfInputHoverBorderColor);
		border-radius: var(--dfInputHoverBorderRadius);
	}

	.drawflow .drawflow-node .outputs {
		float: var(--dfNodeTypeFloat);
	}

	.drawflow .drawflow-node .output {
		right: var(--dfOutputRight);
		background: var(--dfOutputBackgroundColor);
		border: var(--dfOutputBorderSize) solid var(--dfOutputBorderColor);
		border-radius: var(--dfOutputBorderRadius);
		height: var(--dfOutputHeight);
		width: var(--dfOutputWidth);
	}

	.drawflow .drawflow-node .output:hover {
		background: var(--dfOutputHoverBackgroundColor);
		border: var(--dfOutputHoverBorderSize) solid var(--dfOutputHoverBorderColor);
		border-radius: var(--dfOutputHoverBorderRadius);
	}

	.drawflow .connection .main-path {
		stroke-width: var(--dfLineWidth);
		stroke: var(--dfLineColor);
	}

	.drawflow .connection .main-path:hover {
		stroke: var(--dfLineHoverColor);
	}

	.drawflow .connection .main-path.selected {
		stroke: var(--dfLineSelectedColor);
	}

	.drawflow .connection .point {
		stroke: var(--dfRerouteBorderColor);
		stroke-width: var(--dfRerouteBorderWidth);
		fill: var(--dfRerouteBackgroundColor);
	}

	.drawflow .connection .point:hover {
		stroke: var(--dfRerouteHoverBorderColor);
		stroke-width: var(--dfRerouteHoverBorderWidth);
		fill: var(--dfRerouteHoverBackgroundColor);
	}

	.drawflow-delete {
		display: var(--dfDeleteDisplay);
		color: var(--dfDeleteColor);
		background: var(--dfDeleteBackgroundColor);
		border: var(--dfDeleteBorderSize) solid var(--dfDeleteBorderColor);
		border-radius: var(--dfDeleteBorderRadius);
	}

	.parent-node .drawflow-delete {
		top: var(--dfDeleteTop);
	}

	.drawflow-delete:hover {
		color: var(--dfDeleteHoverColor);
		background: var(--dfDeleteHoverBackgroundColor);
		border: var(--dfDeleteHoverBorderSize) solid var(--dfDeleteHoverBorderColor);
		border-radius: var(--dfDeleteHoverBorderRadius);
	}
</style>
